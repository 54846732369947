<template>
  <div class="pricing">
    <div class="row margin-0 justify-content-center">
      <div class="col-md-12 col-lg-10 col-xl-10 col-12 pad-top-35">
        <h2 class="text-center">Pricing</h2>
        <p class="txt-grey text-center">We are the one of the best ad service provider.</p>
        <br>
        <div class="plans">
          <ul class="plans-list">
            <li>
              <h3>Free</h3>
              <h1><span class="fa fa-inr"></span>0<small class="txt-grey">per Ad</small></h1>
              <p class="txt-grey"><span class="fa fa-check"></span> Unlimited Ads</p>
              <p class="txt-grey"><span class="fa fa-times"></span> No Feature Ads</p>
              <p class="txt-grey"><span class="fa fa-phone"></span> Basic support</p>
              <p class="txt-grey"><span class="fa fa-comments"></span> Unlimited Chats</p>
              <p class="txt-grey"><span class="fa fa-whatsapp"></span> Unlimited Whatsapp</p>
              <button class="btn btn-success">Get Started</button>
            </li>
            <li>
              <h3>Basic</h3>
              <h1><span class="fa fa-inr"></span>6<small class="txt-grey">per Ad</small></h1>
              <p class="txt-grey"><span class="fa fa-check"></span> Unlimited Ads</p>
              <p class="txt-grey"><span class="fa fa-check"></span> Unlimited Feature Ads</p>
              <p class="txt-grey"><span class="fa fa-phone"></span> Basic support</p>
              <p class="txt-grey"><span class="fa fa-comments"></span> Unlimited Chats</p>
              <p class="txt-grey"><span class="fa fa-whatsapp"></span> Unlimited Whatsapp</p>
              <button class="btn btn-success">Get Started</button>
            </li>
            <li>
              <h3>Economy</h3>
              <h1><span class="fa fa-inr"></span>4<small class="txt-grey">per Ad</small></h1>
              <p class="txt-grey"><span class="fa fa-check"></span> Unlimited Ads</p>
              <p class="txt-grey"><span class="fa fa-check"></span> Unlimited Feature Ads</p>
              <p class="txt-grey"><span class="fa fa-phone"></span> Basic support</p>
              <p class="txt-grey"><span class="fa fa-comments"></span> Unlimited Chats</p>
              <p class="txt-grey"><span class="fa fa-whatsapp"></span> Unlimited Whatsapp</p>
              <button class="btn btn-success">Get Started</button>
            </li>
            <li>
              <h3>Enterprise</h3>
              <h1>---</h1>
              <p class="txt-grey"><span class="fa fa-check"></span> Unlimited Ads</p>
              <p class="txt-grey"><span class="fa fa-check"></span> Unlimited Feature Ads</p>
              <p class="txt-grey"><span class="fa fa-phone"></span> Basic support</p>
              <p class="txt-grey"><span class="fa fa-comments"></span> Unlimited Chats</p>
              <p class="txt-grey"><span class="fa fa-whatsapp"></span> Unlimited Whatsapp</p>
              <button class="btn btn-success">Contact Now</button>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-12 col-lg-10 col-xl-10 col-12">
        <br>
        <div class="alert alert-info" role="alert">
          <h4 class="alert-heading">Well done!</h4>
          <p>Say no for interepted service on all type of market places. Use our unlimited service and enjoy the unlimited business and earn more with it. We have 24X7 support for reviewing your products and services for unlimited.</p>
          <hr>
          <p class="mb-0">Whenever you need to be sure. one day will defenetly helps your business to grow woth ebechana.com</p>
        </div>
      </div>
    </div>
    <div class="row margin-0 justify-content-center">
      <div class="col-11 pad-top-20">
        <h4 class="margin-bottom-20 text-center">How people save money?</h4>
        <p>Every person will have some reauirements on small and big goods. In that some of the goods are new and some of it are old. When ever buying some old goods needed a report to wheter to buy or not. We will help you out to find so better goods with brand new feature. We are enabled advanced ad posting mechanisms to help you to find better ad postings.</p>
      </div>
      <div class="col-11 pad-top-20">
        <h3 class="text-center">How we help?</h3><br>
        <ul class="list-inline">
          <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon>We are using the best ad optimization for showing your ad to right customer and follow up with perfect communicational methods.</li>
          <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon>One of the best web pattren image placement.</li>
          <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon> We are one of the best free classifieds provider in india.</li>
          <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon> Easy ad featuring with low budget is a wonderful opportunity for business users.</li>
          <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon> We have 24 X 7 customer support to solve your issues and support customer to add content.</li>
          <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon> Unilimited ads posting is the one of the best feature in Ebechana. Most of the people are interested to list their products online.</li>
        </ul><br><br>
        <div class="text-center margin-bottom-40">
          <button class="btn btn-success">Start Posting a free Ad</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      msg: null
    }
  }
}
</script>
<style lang="stylus" scoped>
.pricing
  // padding-top 12vh
  min-height 100vh
  background-color #ffffff
  .plans-list
    list-style none
    margin 0px
    padding 0px
    li
      padding 30px 14px
      float left
      width 23%
      margin 1%
      text-align center
      border 2px solid #dddddd
      h3
        margin 30px 0px
      h1
        margin 30px 0px
      p
        padding 0px 14px
        .fa-check
          color green
        .fa-times
          color red
      button
        margin 30px 0px
</style>
